import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../../util/config';
import { Button, Dialog } from "@material-tailwind/react";
import { HiMiniUser, HiMiniTrash } from "react-icons/hi2";
import YiyersProfile from '../yiyer/YiyersProfile';


function AgendaYiyu() {
  const { id } = useParams();
  const [agenda, setAgenda] = useState([]);
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    fetch(`${config.URL_HOST}/agenda-reuniones-yiyu/${id}`)
      .then(response => response.json())
      .then(data => {
        const formattedData = data.map(item => {
          const fecha = new Date(item.fecha);
          const day = fecha.getDate().toString().padStart(2, '0');
          const month = (fecha.getMonth() + 1).toString().padStart(2, '0');
          const year = fecha.getFullYear();
          const fechaFormateada = `${day}/${month}/${year}`;

          const hora = fecha.getHours().toString().padStart(2, '0');
          const minutos = fecha.getMinutes().toString().padStart(2, '0');
          const horaFormateada = `${hora}:${minutos}`;

          return {
            ...item,
            fecha: fechaFormateada,
            hora: horaFormateada
          };
        });
        setAgenda(formattedData);
      })
      .catch(error => console.error('Error al obtener la agenda de reuniones:', error));
  }, [id]);

  const handleOpenProfileDialog = (userId) => {
    setSelectedUserId(userId);
    setOpenProfileDialog(true);
  };

  const handleCloseProfileDialog = () => {
    setSelectedUserId(null);
    setOpenProfileDialog(false);
  };


  const handleDeleteAgendaEntry = async (agendaId) => {
    try {
      const response = await fetch(`${config.URL_HOST}/agendaryiyer/${agendaId}`, {
        method: 'DELETE'
      });
      if (response.ok) {
        // Actualizar la agenda después de eliminar la entrada
        const updatedAgenda = agenda.filter(item => item.id !== agendaId);
        setAgenda(updatedAgenda);
      } else {
        console.error('Error al eliminar la entrada de la agenda.');
      }
    } catch (error) {
      console.error('Error al eliminar la entrada de la agenda:', error);
    }
  };

  return (
    <section className="flex flex-col sm:flex-row justify-center align-middle w-full bg-gray-50 p-4 font-outfit py-10">
      <div className="flex flex-col justify-center items-center w-full bg-white p-8 rounded-lg shadow-md gap-3 max-w-[800px]">
        <h1 className="font-bold text-2xl self-start">Agenda de reuniones</h1>
        <p className='self-start text-sm text-gray-800'>Acá puedes revisar las sesiones que tienes agendadas</p>
        <table className="min-w-full leading-normal mt-5">
          <thead>
            <tr>
              <th scope="col" className="px-3 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200">Yiyer</th>
              <th scope="col" className="px-3 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200">Fecha</th>
              <th scope="col" className="px-3 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200">Hora</th>
              <th scope="col" className="px-3 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {agenda.map(item => (
              <tr key={item.id}>
                <td className="px-3 py-5 text-xs md:text-sm bg-white border border-gray-200 text-center">{item.nombre} {item.apellido}</td>
                <td className="px-3 py-5 text-xs md:text-sm bg-white border border-gray-200 text-center">{item.fecha}</td>
                <td className="px-3 py-5 text-xs md:text-sm bg-white border border-gray-200 text-center">{item.hora}</td>
                <td className="px-3 py-5 gap-3 text-xs md:text-sm bg-white border border-gray-200">
                  <Button color='green' className="flex flex-row rounded-full px-2 py-1 text-lg w-full md:w-36 mx-auto text-center" onClick={() => handleOpenProfileDialog(item.idyiyer)}><HiMiniUser /> <span className='text-xs font-normal font-outfit ml-3'>Perfil</span> </Button>
                  <Button color='red' className="flex flex-row rounded-full px-2 py-1 text-lg w-full md:w-36 mt-2 mx-auto text-center" onClick={() => handleDeleteAgendaEntry(item.id)}><HiMiniTrash /><span className='text-xs font-normal font-outfit ml-3'>Eliminar</span> </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Dialog para mostrar el perfil del usuario */}
      <Dialog size="md" open={openProfileDialog} toggler={handleCloseProfileDialog}>
      <div className="flex w-full object-right justify-end">
        <Button
          className="bg-blue-gray-200 text-lg px-3 py-1 mt-2 mr-2"
          onClick={handleCloseProfileDialog}
        >
          X
        </Button>
      </div>
        <Dialog.Body>
          {selectedUserId && <YiyersProfile id={selectedUserId} />} {/* Pasa el ID del usuario al componente de perfil */}
        </Dialog.Body>
      </Dialog>
    </section>
  );
}

export default AgendaYiyu;
