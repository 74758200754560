import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContextUser } from "../../context/ContextUser";
import { useAuth } from "../../context/AuthContext";

function ButtonsLoginRegisterHeader() {
  const { yiyerData } = useContext(ContextUser);
  const [logued, setLogued] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
   yiyerData.nombre === 'non_user_logued' ? setLogued(false) : setLogued(true)
  }, [yiyerData]);

  return (
    <div className={`hidden ${ isAuthenticated ? 'sm:hidden' : 'sm:flex' }  flex-row gap-2`}>
            <button
              onClick={() => navigate("/login")}
              className="transition ease-in-out delay-15 items-center bg-Black text-White rounded-lg text-xs px-2 h-8 min-w-fit hover:-translate-y-1 hover:scale-110 hover:bg-Gray-5 duration-300"
            >
              Iniciar Sesión
            </button>
            <button
              onClick={() => navigate("/registro")}
              className="transition ease-in-out delay-15 items-center bg-White text-Black rounded-lg text-xs px-2 h-8 min-w-fit border-2 border-White hover:-translate-y-1 hover:scale-110 hover:border-Black duration-300"
            >
              Registrar
            </button>
          </div>
  )
}

export default ButtonsLoginRegisterHeader