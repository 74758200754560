import React, { useEffect, useState } from "react";
import { Avatar, Button, Rating, Spinner } from "@material-tailwind/react";
import { MdOutlinePlace, MdOutlineMessage } from "react-icons/md";
import imagesApp from "../../assets/images/staticimgs";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../util/config";
import MiDisponibilidadProfileYiyerPublic from "./MiDisponibilidadProfileYiyerPublic";

const YiyersProfile = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const storedUserData = JSON.parse(localStorage.getItem("userData") || '{}'); // Asegurarse de que siempre sea un objeto

  useEffect(() => {
    axios
      .get(`${config.URL_HOST}/yiyersprofile/${id}`)
      .then((response) => {
        if (response.status === 404) {
          throw new Error("Usuario no encontrado");
        }
        if (!response.data) {
          throw new Error("Error interno del servidor");
        }
        setUserData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  if (userData === null) {
    return (
      <div className="flex flex-row justify-center object-center p-20">
        <Spinner />
      </div>
    );
  }

  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };

  return (
    <section className="flex flex-col sm:flex-row justify-center align-middle w-full bg-gray-50 p-4 font-outfit py-20">
      {userData && (
        <>
          <div className="flex flex-col w-4/5 md:w-3/5 lg:w-4/5 p-5 bg-white rounded-xl shadow-xl mx-auto min-w-[340px] gap-3">
            {/* Verificar que storedUserData no es nulo y que tiene una propiedad 'rol' */}
            {storedUserData && storedUserData.rol === "yiyu" && (
              <Link to={`/agendaryiyer/${id}`} className="flex w-full object-right justify-end">
                <Button color="green">Agendar sesión {`>`}</Button>
              </Link>
            )}
            <div className="flex flex-row gap-5">
              {userData.imgurl ? (
                <Avatar
                  src={replaceImageUrl(userData.imgurl)}
                  alt="avatar"
                  size="xxl"
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = imagesApp.noImg;
                  }}
                />
              ) : (
                <img
                  src={imagesApp.noImg}
                  alt="avatar"
                  className="w-20 h-20 rounded-full"
                />
              )}
              <div className="flex flex-col justify-center">
                <h1 className="font-black text-xl text-gray-900">
                  {userData.nombre} {userData.apellido}
                </h1>
                <p className="text-gray-800 font-bold text-sm">
                  {userData.profesion}
                </p>
                
                <p className="flex flex-row align-middle text-sm text-blue-gray-700">
                  <MdOutlinePlace className="my-0.5" /> {userData.pais}
                </p>
                <Rating readonly />
              </div>
            </div>

        <div className="flex flex-wrap gap-1">
              {userData.categoria
          .slice(1, -1)
          .split('","')
          .map((item, index) => (
            <span key={index} className="border border-orange-400 rounded-full px-2 py-1 mt-2 mr-2 text-xs text-orange-400">{item.replace(/"/g, '')}</span>
          ))}
        </div>

            <div className="flex flex-col w-full mt-4 py-3">
            <h1 className="flex flex-row gap-2 items-center text-base font-bold mb-5 text-gray-900"><div className="bg-orange-400 w-3 h-3"></div>Introducción</h1>
              <p className="text-sm text-gray-800">{userData.intro}</p>
            </div>

            <div className="flex flex-col w-full py-3">
            <h1 className="flex flex-row gap-2 items-center text-base font-bold mb-5 text-gray-900"><div className="bg-orange-400 w-3 h-3"></div>Experiencia</h1>
              <p className="text-sm text-gray-800">{userData.experiencia}</p>
            </div>

            <h1 className="flex flex-row gap-2 items-center text-base font-bold text-gray-900"><div className="bg-orange-400 w-3 h-3"></div>Proximas fechas disponibles</h1>
            <MiDisponibilidadProfileYiyerPublic userId={userData.id} />
          </div>
        </>
      )}
    </section>
  );
};

export default YiyersProfile;
