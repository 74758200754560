import axios from "axios";
import React, { useState } from "react";
import config from "../../util/config";
import MensajeErrorForms from "./MensajeErrorForms";
import CardYiyerMuestraSignUp from "./CardYiyerMuestraSignUp";
import {
  Input,
  Textarea,
  Button,
  Spinner,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash  } from "react-icons/fa6";

import Select from "react-select";

function FormRegistroYiyer() {
  const [errorRegister, setErrorRegister] = useState(false);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passVisible, setPassVisible] = useState(false)
  const navigate = useNavigate();

  const [form, setForm] = useState({
    nombre: "",
    apellido: "",
    email: "",
    pais: "",
    profesion: "",
    categoria: [],
    linkedin: "",
    intro: "",
    temas: "",
    experiencia: "",
    rol: "yiyer",
    foto: null,
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
    setErrorRegister(false);
  };

  const handleFileChange = (e) => {
    setForm({ ...form, foto: e.target.files[0] });
  };

  const handleCategoryChange = (selectedOptions) => {
    const selectedCategories = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setForm((prev) => ({ ...prev, categoria: selectedCategories }));
    setErrorRegister(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => formData.append(key, item));
      } else {
        formData.append(key, value);
      }
    });

    try {
      const response = await axios.post(
        `${config.URL_HOST}/registro`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response && response.data) {
        setRegistroExitoso(true);
      } else {
        console.error("La respuesta del servidor no contiene datos");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.code === "23505") {
        setErrorText("El email ya está registrado");
        setErrorRegister(true);
      } else {
        console.error("Error al realizar la solicitud:", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const categoryOptions = [
    { value: "Tecnología", label: "Tecnología" },
    { value: "Comunicación", label: "Comunicación" },
    { value: "Liderazgo", label: "Liderazgo" },
    { value: "Relatos - Story Telling", label: "Relatos - Story Telling" },
    { value: "Hablar en publico", label: "Hablar en publico" },
    { value: "Transformacion Organizacional", label: "Transformacion Organizacional" },
    { value: "Sostenibilidad", label: "Sostenibilidad" },
    { value: "Transformacion Digital", label: "Transformacion Digital" },
    { value: "Economía", label: "Economía" },
    { value: "Talento", label: "Talento" },
    { value: "Trabajo Híbrido", label: "Trabajo Híbrido" },
    { value: "Negociación", label: "Negociación" },
    { value: "Educación", label: "Educación" },
    { value: "Redes sociales", label: "Redes sociales" },
    { value: "Marca Personal", label: "Marca Personal" },
    { value: "Familia y Trabajo", label: "Familia y Trabajo" },
    { value: "Relaciones de Pareja", label: "Relaciones de Pareja" },
    { value: "Paternidad", label: "Paternidad" },
    { value: "Maternidad", label: "Maternidad" },
    { value: "Emprendimiento", label: "Emprendimiento" },
    { value: "Startups", label: "Startups" },
    { value: "Salud Mental", label: "Salud Mental" },
    { value: "Arte", label: "Arte" },
    { value: "Empleo", label: "Empleo" },
    { value: "Adicciones", label: "Adicciones" },
    { value: "Teletrabajo", label: "Teletrabajo" },
    { value: "Inteligencia Artificial", label: "Inteligencia Artificial" },
    { value: "Innovación", label: "Innovación" },
    { value: "Marketing Digital", label: "Marketing Digital" },
    { value: "Comunidad", label: "Comunidad" },
    { value: "Equipo", label: "Equipo" },
    { value: "Vocería", label: "Vocería" },
    { value: "Presentaciones", label: "Presentaciones" },
    { value: "Experiencia", label: "Experiencia" },
    { value: "Pensamiento Crítico", label: "Pensamiento Crítico" },
    { value: "Diversidad", label: "Diversidad" },
    { value: "Seguridad", label: "Seguridad" },
    { value: "Relaciones afectivas", label: "Relaciones afectivas" },
    { value: "Igualdad", label: "Igualdad" },
    { value: "Religión", label: "Religión" },
    { value: "Cultura", label: "Cultura" },
    { value: "Estereotipos", label: "Estereotipos" },
    { value: "Política", label: "Política" },
    { value: "Libertad de expresión", label: "Libertad de expresión" },
    { value: "Salud Física", label: "Salud Física" },
    { value: "Vacaciones", label: "Vacaciones" },
    { value: "Viajes", label: "Viajes" }
  ];

  return (
    <section className="flex flex-row justify-center align-middle p-10 w-full bg-Gray-1 font-outfit gap-4">
      {!registroExitoso ? (
        <>
          <CardYiyerMuestraSignUp />
          <form
            onSubmit={handleSubmit}
            method="POST"
            encType="multipart/form-data"
            className="flex flex-col gap-3 bg-white p-8 rounded-xl shadow-md min-w-[400px] w-full lg:w-10/12"
          >
            {errorRegister ? (
              <MensajeErrorForms texto={errorText} open={errorRegister} />
            ) : null}
            <h2 className="text-2xl font-bold mb-5 text-orange-400">
              Conviértete en un Yiyer
            </h2>
            <div className="flex flex-col sm:flex-row gap-3">
              <Input
                label="Nombre"
                name="nombre"
                onChange={handleChange}
                value={form.nombre}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
              <Input
                label="Apellido"
                name="apellido"
                onChange={handleChange}
                value={form.apellido}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <Input
                label="Email"
                name="email"
                onChange={handleChange}
                value={form.email}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                autoComplete="email"
                required
              />
              <Input
                label="País"
                name="pais"
                onChange={handleChange}
                value={form.pais}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-3">
              <Input
                label="Profesión u oficio"
                name="profesion"
                onChange={handleChange}
                value={form.profesion}
                className="block w-full p-2 rounded border border-gray-300 mb-3"
                required
              />
            </div>
            <div className="flex flex-col sm:flex-row gap-3">
              <div className="w-full">
                <label className="block text-gray-700">Temas de interés</label>
                <Select
                  isMulti
                  options={categoryOptions}
                  onChange={handleCategoryChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder='Selecciona una o más categorías'
                />
              </div>
            </div>
            <Input
              label="Linkedin"
              name="linkedin"
              onChange={handleChange}
              value={form.linkedin}
              className="block w-full p-2 rounded border border-gray-300 mb-3"
            />
            <Textarea
              label="Introducción (preséntate en un texto breve)"
              name="intro"
              onChange={handleChange}
              value={form.intro}
              className="bg-transparent border-0 outline-0 appearance-none w-full p-2"
            />
            <Textarea
              label="Temáticas que quiero resolver (si es más de una, sepáralas con coma)"
              variant="outlined"
              name="temas"
              onChange={handleChange}
              value={form.temas}
              className="flex justify-start items-start appearance-none w-full p-2"
            />
            <Textarea
              label="Resumen de tu experiencia laboral"
              variant="outlined"
              name="experiencia"
              onChange={handleChange}
              value={form.experiencia}
              className="flex justify-start items-start appearance-none w-full p-2"
            />
            <Input
              label="Imagen de perfil"
              size="lg"
              type="file"
              name="foto"
              accept="image/*"
              onChange={handleFileChange}
              className="appearance-auto w-full px-6"
              required
            />
            <div className="relative flex w-full">
            <Input
              label="Contraseña"
              name="password"
              type={passVisible ? "text" : "password"}
              onChange={handleChange}
              value={form.password}
              className="block w-full p-2 rounded border border-gray-300 mb-3"
              required
            />

            <Button
            onClick={() => setPassVisible(!passVisible)}
            variant="text"
            size="sm"
            disabled={!form.password}
            className={
              passVisible
                ? "!absolute bg-transparent right-1 top-1 rounded text-gray-500 text-lg"
                : "!absolute bg-transparent right-1 top-1 rounded text-gray-500 text-lg"
            }
            >
              {passVisible ? <FaRegEyeSlash /> : <FaRegEye />}
            </Button>

</div>

            <Button
              type="submit"
              className="block bg-orange-500 hover:bg-orange-400 p-2 rounded text-white font-bold cursor-pointer py-4 mt-6 w-3/4 mx-auto"
              disabled={isLoading}
            >
              {isLoading ? <Spinner color="red" className="h-5 w-5 mx-auto" /> : "Registrarse"}
            </Button>
          </form>
        </>
      ) : (
        <div className="flex flex-col justify-center align-middle text-center gap-3 bg-white p-8 rounded-xl shadow-md w-full">
          <h1 className="text-3xl font-extrabold text-Flamingo-0">
            Felicidades
          </h1>
          <p className="text-lg text-gray-900">¡Ya eres un Yiyer!</p>
          <Button onClick={() => navigate("/login")}>Iniciar Sesión</Button>
        </div>
      )}
    </section>
  );
}

export default FormRegistroYiyer;
