import React, { useContext } from 'react'
import { ContextUser } from "../../context/ContextUser";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';

function LogoutButton() {
    const { setYiyerData } = useContext(ContextUser);
    const navigate = useNavigate();
  const { logout } = useAuth();

    const handleLogout = () => {
      setYiyerData({
        nombre: 'non_user_logued'
      });
        localStorage.removeItem('userData');
        logout();
        navigate("/");
      };
  return (
    <span className="bg-Black text-white text-sm font-outfit hover:bg-blue-gray-900 px-3 py-1 rounded-lg self-center mx-auto" onClick={handleLogout}>Cerrar Sesión</span>
  )
}

export default LogoutButton