import React from 'react'
import { Avatar, Rating, Button } from "@material-tailwind/react";
import imagesApp from '../../assets/images/staticimgs';
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const CardYiyers = ({id, name, lastname, picture, profesion}) => {

  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };

  return (
<div className="flex flex-col justify-center items-center w-8/12 sm:w-60 text-sm space-y-3 p-4 bg-White shadow-md rounded-xl mx-auto h-80" >
      <Avatar src={replaceImageUrl(picture)} alt="name" size="xxl" loading="lazy" onError={(e) => {
              e.target.src = imagesApp.noImg;
            }} />

      <Rating readonly />

        
      <h1 className="text-md font-bold text-left self-start">{name + " " + lastname}</h1>
      <p className="text-start text-md">
      {profesion}
      </p>
      <Link to={`/yiyersprofile/${id}`}>
      <Button variant="outlined" className="w-full hover:bg-orange-400 hover:border-0 hover:text-white">Ver perfil</Button>
      </Link>
    </div>
  )
}

export default CardYiyers