import React from "react";


function CardComoFuncionaH({ titulo, orden, image }) {
  return (
    <div className="bg-white w-56 flex-shrink-0 p-5 rounded-2xl relative shadow-lg">
      <img src={ image } alt="card" className="object-cover w-full absolute top-0 left-0 right-0 h-32 rounded-t-2xl shadow-md" />
      <div className="absolute left-2 top-2 rounded-full bg-Flamingo-0 text-white text-center w-6">
        { orden }
      </div>

      <div className="w-full mx-0 p-0 text-2xl font-medium mt-32">
      { titulo }
      </div>
        
    </div>
  );
}

export default CardComoFuncionaH;
