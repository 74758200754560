import React, { useState } from "react";
import YifyLogo from "../../assets/images/Logo.svg";
import { useNavigate, Link } from "react-router-dom";
import config from "../../util/config";
import axios from "axios";
import MensajeErrorForms from "./MensajeErrorForms";
import { useAuth } from "../../context/AuthContext";
import { Spinner } from "@material-tailwind/react";


function LoginForm() {
  
  const [email, setEmailLocal] = useState("");
  const [password, setPasswordLocal] = useState("");
  const [checkLogin, setChecklogin] = useState(false);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false)

  const { login, isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const datos = {
    email: email,
    password: password,
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    if (!e.target.checkValidity()) {
    } else {
      try {
        const res = await axios.post(`${config.URL_HOST}/login`, datos);
        const token = await res.data.token;
        const userData = await res.data.userData;
        localStorage.setItem("userData", JSON.stringify(userData));
        login(token);
        if (userData.rol === 'yiyer' || userData.rol === 'Yiyer') {
          navigate("/miperfil");
        } else if (userData.rol === 'yiyu') {
          navigate("/yiyuprofile");
        }
        res.statusText === "OK" ? setChecklogin(true) : setChecklogin(false);
      } catch (error) {
        setLoading(false)
        console.log(error)
          if (error.response.status === 401) {
            setErrorText("Usuario o contraseña incorrectos");
            setErrorLogin(true);
          } else if (error.response.status === 500) {
            setErrorText("Debes ingresar tus datos");
            setErrorLogin(true);
          }
      }
    }
  };


  

  return (
    <div
      className={`flex min-h-full flex-col justify-center px-6 py-12 mb-9 lg:px-8 ${
        checkLogin === true ? `invisible` : `visible`
      } `}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-10 w-auto" src={YifyLogo} alt="Logo Yiyaz" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Inicia Sesión
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setEmailLocal(e.target.value)
                  setErrorLogin(false)
                }}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-Flamingo-0 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Contraseña
              </label>
              <div className="text-sm">
                {/* <a
                  href="#"
                  className="font-semibold text-Bunting-0 hover:text-Bunting-1"
                >
                  ¿Olvidaste tu contraseña?
                </a> */}
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => {
                  setPasswordLocal(e.target.value)
                  setErrorLogin(false)
                }}
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-Flamingo-0 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              onClick={handleSubmit}
              className="flex w-full justify-center rounded-md bg-Flamingo-0 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-Flamingo-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-Flamingo-0">
              {loading ? <Spinner  color="amber" /> : 'Iniciar Sesión'}
            </button>
          </div>
          {errorLogin ? <MensajeErrorForms texto={errorText} open={errorLogin} /> : null}
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          ¿Aún no eres yiyer?
          <Link to={"/registro"}>
            <span className="font-semibold leading-6 text-Bunting-0 hover:text-Bunting-1">
              {" "}
              Registrate
            </span>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default LoginForm;
