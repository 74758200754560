import React, { useState, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Button, Collapse, Alert, Spinner } from "@material-tailwind/react";
import config from "../../util/config";
import { FaTrash } from "react-icons/fa";

const EditarAgendaYiyer = ({ userId, handleOpen }) => {
  const [selectedRange, setSelectedRange] = useState([new Date(), new Date()]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [agendaList, setAgendaList] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRangeChange = useCallback((dates) => {
    const [start, end] = dates;
    setSelectedRange([start, end]);
    setIsSuccess(false);
  }, []);

  const handleAddAgenda = () => {
    const [start, end] = selectedRange;
    if (startTime && endTime && start && end) {
      const newAgendaItems = [];
      let currentDate = new Date(start);

      while (currentDate <= end) {
        newAgendaItems.push({
          date: new Date(currentDate),
          startTime,
          endTime,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setAgendaList((prevAgendaList) => [...prevAgendaList, ...newAgendaItems]);
      setSelectedRange([new Date(), new Date()]);
      setStartTime("");
      setEndTime("");
    }
  };

  const handleDeleteAgenda = useCallback((index) => {
    setAgendaList((prevAgendaList) =>
      prevAgendaList.filter((_, i) => i !== index)
    );
  }, []);

  const handleSaveAvailability = () => {
    setIsLoading(true);
    const availabilityData = {
      userId,
      agendaList,
    };
    axios
      .post(`${config.URL_HOST}/disp_yiyer`, availabilityData)
      .then((response) => {
        setIsLoading(false);
        setIsSuccess(true);
        setAgendaList([]);
        setError("");
      })
      .catch((error) => {
        setIsLoading(false);
        setError("Ha surgido un problema. Inténtalo más tarde.");
      });
  };

  return (
    <div
      className="p-5 font-outfit overflow-visible"
      style={{ maxHeight: "100vh", overflow: "auto" }}
    >
      <div className="flex w-full object-right justify-end">
        <Button
          className="mb-4 bg-blue-gray-200 text-lg px-3 py-1"
          onClick={handleOpen}
        >
          X
        </Button>
      </div>

      <div
        className={`flex flex-col align-middle justify-center w-full ${
          handleOpen ? "block" : "hidden"
        }`}
      >
        <p className="mx-4 mb-7 text-sm">
          Selecciona una fecha y un rango de horas, luego presiona Agregar. Una
          vez agregadas todas las fechas, presiona{" "}
          <strong className="font-extrabold">Guardar Disponibilidad</strong>{" "}
          para agregarlas a tu agenda
        </p>
        <div className="flex flex-col gap-3 items-center justify-center bg-blue-gray-50 rounded-lg p-6 text-sm">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="flex flex-col gap-1 object-contain border border-blue-gray-200 p-3 rounded-md">
              <h2>Fecha</h2>
              <DatePicker
                selected={selectedRange[0]}
                onChange={handleRangeChange}
                startDate={selectedRange[0]}
                endDate={selectedRange[1]}
                selectsRange
                dateFormat="dd/MM/yyyy"
                className="mb-1 p-3 rounded-lg"
              />
            </div>

            <div className="flex flex-col gap-1 border border-blue-gray-200 p-3 rounded-md">
              <h2>Rango de Horas</h2>
              <div className="flex">
                <select
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className="mt-1 rounded-md border-gray-500 mr-1"
                >
                  {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
                <span className="flex justify-center items-center">-</span>
                <select
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className="mt-1 rounded-md border-gray-500 ml-1"
                >
                  {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                    <option
                      key={hour}
                      value={`${hour}:00`}
                    >{`${hour}:00`}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <Button
            onClick={handleAddAgenda}
            size="sm"
            className="h-10 my-auto bg-green-500 text-white w-52 mt-4 mx-auto"
          >
            Agregar
          </Button>
        </div>

        <div className="mt-4">
          <h2 className="text-xl font-bold mb-2">Agenda</h2>
          <table className="w-full border-collapse border table-auto">
            <thead>
              <tr className="bg-gray-200 text-xs">
                <th className="border p-1">Fecha</th>
                <th className="border p-1">Hora de Inicio</th>
                <th className="border p-1">Hora de Termino</th>
                <th className="border p-1">Eliminar</th>
              </tr>
            </thead>
            <tbody>
              {agendaList.map((item, index) => (
                <tr key={index} className="text-xs">
                  <td className="border p-1 text-center">
                    {format(item.date, "dd/MM/yyyy", { locale: es })}
                  </td>
                  <td className="border p-1 text-center">{item.startTime}</td>
                  <td className="border p-1 text-center">{item.endTime}</td>
                  <td className="border p-1 text-center">
                    <Button
                      size="sm"
                      onClick={() => handleDeleteAgenda(index)}
                      className="bg-white text-Semantic-Error font-normal font-outfit p-1 text-xs"
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Button
        onClick={handleSaveAvailability}
        className="mt-4 mx-auto px-4 py-5 text-base w-full"
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner className=" self-center w-5 h-5 mx-auto" />
        ) : (
          "Guardar Disponibilidad"
        )}
      </Button>
      <Collapse open={isSuccess}>
        <Alert
          variant="outlined"
          color="green"
          className="mt-2 mx-auto text-center justify-center"
        >
          Datos guardados exitosamente
        </Alert>
      </Collapse>
      {error && (
        <Alert
          variant="outlined"
          color="red"
          className="mb-2 bg-Flamingo-3 border-Flamingo-1 border-2 text-Flamingo-0"
        >
          {error}
        </Alert>
      )}
    </div>
  );
};

export default EditarAgendaYiyer;
