import home01 from './pages/3560e7d7f5333ce12e6fe9be49d5d72c.jpg'
import home02 from './pages/196efbe5caf30379ffd2470903e4cbe5.jpg'
import home03 from './pages/54949f1170bcdfbb017dbd9f92c96c9f.jpg'
import about01 from './pages/about_01.jpg'
import team01 from './pages/equipo01.jpg'
import team02 from './pages/equipo02.jpg'
import team03 from './pages/equipo03.jpg'
import team04 from './pages/equipo04.jpg'
import team05 from './pages/equipo05.jpg'
import HowWorks_01 from './pages/HowWorks_01.jpg'
import HowWorks_02 from './pages/HowWorks_02.jpg'
import HowWorks_03 from './pages/HowWorks_03.jpg'
import HowWorks_04 from './pages/HowWorks_04.jpg'
import wom_Banner_01 from './pages/wom_Banner_01.png'
import bg_banner01 from './pages/bg_banner01.png'
import bg_banner02 from './pages/bg_banner02.png'
import bg_banner03 from './pages/bg_banner03.png'
import bg_banner_color from './pages/bg_banner_color.png'
import graphElement01 from './circles_dec_01.png'
import profileIcon from './profile_icon.png'
import how01 from './pages/how_01.jpg'
import how02 from './pages/how_02.jpg'
import how03 from './pages/how_03.jpg'
import how04 from './pages/how_04.jpg'
import noImg from './noImg.png'

const imagesApp = {
    'home01': home01,
    'home02': home02,
    'home03': home03,
    'about01': about01,
    'team01': team01,
    'team02': team02,
    'team03': team03,
    'team04': team04,
    'team05': team05,
    'HowWorks_01': HowWorks_01,
    'HowWorks_02': HowWorks_02,
    'HowWorks_03': HowWorks_03,
    'HowWorks_04': HowWorks_04,
    'wom_Banner_01': wom_Banner_01,
    'bg_banner01': bg_banner01,
    'bg_banner02': bg_banner02,
    'bg_banner03': bg_banner03,
    'bg_banner_color': bg_banner_color,
    'graphElement01': graphElement01,
    'profileIcon': profileIcon,
    'how01': how01,
    'how02': how02,
    'how03': how03,
    'how04': how04,
    'noImg': noImg,
};

export default imagesApp