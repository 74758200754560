import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "../../util/config";
import { Button } from "@material-tailwind/react";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi2";

function AgendaYiyer() {
  const { id } = useParams();
  const [agenda, setAgenda] = useState([]);

  useEffect(() => {
    fetch(`${config.URL_HOST}/agenda-reuniones-yiyer/${id}`)
      .then((response) => response.json())
      .then((data) => {
        const formattedData = data.map((item) => {
          // Formatear la fecha
          const fecha = new Date(item.fecha);
          const day = fecha.getDate().toString().padStart(2, "0");
          const month = (fecha.getMonth() + 1).toString().padStart(2, "0");
          const year = fecha.getFullYear();
          const fechaFormateada = `${day}/${month}/${year}`;

          // Separar la hora y los minutos
          const hora = fecha.getHours().toString().padStart(2, "0");
          const minutos = fecha.getMinutes().toString().padStart(2, "0");
          const horaFormateada = `${hora}:${minutos}`;

          return {
            ...item,
            fecha: fechaFormateada,
            hora: horaFormateada,
          };
        });
        setAgenda(formattedData);
      })
      .catch((error) =>
        console.error("Error al obtener la agenda de reuniones:", error)
      );
  }, [id]);

  const handleConfirm = async (itemId) => {
    try {
      // Confirmar la reunión en la base de datos
      await fetch(`${config.URL_HOST}/confirmacion-agenda-reuniones/${itemId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ estado: "confirmada" }),
      });
  
      // Obtener los detalles de la reunión del item correspondiente
      const selectedMeeting = agenda.find((item) => item.id === itemId);
  
      // Crear la reunión en Zoom
      await fetch(`${config.URL_HOST}/crear-reunion-zoom/${itemId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          topic: "Sesión con yiyer",
          start_time: selectedMeeting.fecha,
          duration: 60,
          timezone: "UTC",
          agenda: "Reunion yiyaz",
        }),
      });
  
      // Actualizar el estado de la reunión en el estado local
      const updatedAgenda = agenda.map((item) => {
        if (item.id === itemId) {
          return { ...item, estado: "confirmada" };
        }
        return item;
      });
      setAgenda(updatedAgenda);
  
      // Mensaje de éxito
      console.log("Reunión confirmada y creada en Zoom correctamente.");
    } catch (error) {
      console.error("Error al confirmar la reunión:", error);
    }
  };

  const handleReject = async (itemId) => {
    try {
      await fetch(
        `${config.URL_HOST}/confirmacion-agenda-reuniones/${itemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ estado: "rechazada" }),
        }
      );
      // Actualizar la agenda después de la confirmación
      const updatedAgenda = agenda.map((item) => {
        if (item.id === itemId) {
          return { ...item, estado: "rechazada" };
        }
        return item;
      });
      setAgenda(updatedAgenda);
    } catch (error) {
      console.error("Error al rechazar la reunión:", error);
    }
  };



   return (
    <section className="flex flex-col sm:flex-row justify-center align-middle w-full bg-gray-50 p-4 font-outfit py-10">
      <div className="flex flex-col justify-center items-center w-full bg-white p-8 rounded-lg shadow-md gap-3 max-w-[800px]">
        <h1 className="font-bold text-2xl self-start">Agenda de reuniones</h1>
        <p className="self-start text-sm text-gray-800">
          Acá puedes revisar las sesiones que tienes agendadas
        </p>
        <table className="min-w-full leading-normal mt-5 text-center">
          <thead>
            <tr>
              <th
                scope="col"
                className="px-2 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200"
              >
                Yiyer
              </th>
              <th
                scope="col"
                className="px-2 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200"
              >
                Fecha
              </th>
              <th
                scope="col"
                className="px-2 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200"
              >
                Hora
              </th>
              {/* <th scope="col" className="px-5 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200">Estado</th> */}
              <th
                scope="col"
                className="px-2 py-3 text-xs md:text-sm font-normal text-center text-gray-800 uppercase bg-blue-gray-100 border border-gray-200"
              >
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
  {agenda.map((item) => {
    // Obtener la fecha actual y la fecha de la reunión
    const currentDate = new Date();
    const [day, month, year] = item.fecha.split('/'); // Dividir la fecha en día, mes y año
    const meetingDate = new Date(year, month - 1, day); // Restar 1 al mes ya que en JavaScript los meses van de 0 a 11

    // Comparar la fecha de la reunión con la fecha actual
    if (meetingDate > currentDate) {
      return (
        <tr key={item.id}>
          <td className="px-2 py-5 text-xs md:text-sm bg-white border border-gray-200">
            {item.nombre} {item.apellido}
          </td>
          <td className="px-2 py-5 text-xs md:text-sm bg-white border border-gray-200">
            {item.fecha}
          </td>
          <td className="px-2 py-5 text-xs md:text-sm bg-white border border-gray-200">
            {item.hora}
          </td>
          <td className="flex flex-row items-center justify-center gap-1 px-2 py-5 text-xs md:text-sm bg-white border border-gray-200">
            {item.estado === "confirmada" ? (
              <p className="text-green-500">Confirmada</p>
            ) : item.estado === "rechazada" ? (
              <p className="text-red-600">Rechazada</p>
            ) : (
              <>
                <div className="text-xs w-1/2 px-2">
                  <Button
                    className="p-2 bg-green-500 text-xl mb-2"
                    onClick={() => handleConfirm(item.id)}
                  >
                    <HiOutlineCheckCircle />
                  </Button>
                  <p className="border-t border-t-blue-gray-200">
                    Confirmar
                  </p>
                </div>

                <div className="text-xs w-1/2 px-2">
                  <Button
                    className="p-2 bg-red-600 text-xl mb-2"
                    onClick={() => handleReject(item.id)}
                  >
                    <HiOutlineXCircle />
                  </Button>
                  <p className="border-t border-t-blue-gray-200">
                    Rechazar
                  </p>
                </div>
              </>
            )}
          </td>
        </tr>
      );
    } else {
      // Si la reunión ya pasó, no mostrarla
      return null;
    }
  })}
</tbody>



        </table>
      </div>
    </section>
  );
}

export default AgendaYiyer;
