import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../util/config";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Collapse, Alert, Spinner } from "@material-tailwind/react";
import AgendamientoCalendario from "../components/Agendamiento/AgendamientoCalendario";
import DatosYiyerAgendamiento from "../components/Agendamiento/DatosYiyerAgendamiento";


function AgendarYiyer_v2() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(null);
  const [yiyerData, setYiyerData] = useState([]);
  const [disponibilidadYiyer, setDisponibilidadYiyer] = useState([]);
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [horaSeleccionada, setHoraSeleccionada] = useState(null);
  const [horasDisponibles, setHorasDisponibles] = useState([]);
  const [horariosOcupados, setHorariosOcupados] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileResponse = await axios.get(
          `${config.URL_HOST}/yiyersprofile/${id}`
        );
        if (profileResponse.status === 404) {
          throw new Error("Usuario no encontrado");
        }
        if (!profileResponse.data) {
          throw new Error("Error interno del servidor al obtener el perfil");
        }
        setYiyerData(profileResponse.data);

        const availabilityResponse = await axios.get(
          `${config.URL_HOST}/midisponibilidad/${id}`
        );
        if (availabilityResponse.status === 404) {
          throw new Error("Disponibilidad no encontrada");
        }
        if (!availabilityResponse.data) {
          throw new Error(
            "Error interno del servidor al obtener la disponibilidad"
          );
        }
        setDisponibilidadYiyer(availabilityResponse.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false); // Una vez que se completan las solicitudes, establecer isLoading en false
      }
    };

    fetchData();
  }, [id, navigate]);

  useEffect(() => {
    if (fechaSeleccionada) {
      const fechaSeleccionadaString = fechaSeleccionada
        .toISOString()
        .split("T")[0];
      const disponibilidadFecha = disponibilidadYiyer.find(
        (disponibilidad) =>
          disponibilidad.date.split("T")[0] === fechaSeleccionadaString
      );
      if (disponibilidadFecha) {
        const horasDisponiblesArray = [];
        let hora = parseInt(disponibilidadFecha.start_time.split(":")[0]);
        const horaFin = parseInt(disponibilidadFecha.end_time.split(":")[0]);
        while (hora < horaFin) {
          horasDisponiblesArray.push(`${hora}:00`);
          hora++;
        }
        setHorasDisponibles(horasDisponiblesArray);
      } else {
        setHorasDisponibles([]);
      }
    }
  }, [fechaSeleccionada, disponibilidadYiyer]);

  const fetchHorariosOcupados = async () => {
    try {
      const response = await axios.get(
        `${config.URL_HOST}/horariosOcupados/${yiyerData.id}`
      );
      setHorariosOcupados(response.data.horariosOcupados);
    } catch (error) {
      console.error("Error al obtener los horarios ocupados:", error);
    }
  };

  useEffect(() => {
    if (fechaSeleccionada && yiyerData.id) {
      fetchHorariosOcupados();
    }
  }, [fechaSeleccionada, yiyerData.id]);

  const handleFechaSeleccionada = (fecha) => {
    setFechaSeleccionada(fecha);
    setHoraSeleccionada(null);
    fetchHorariosOcupados(); // Hacer la solicitud GET al servidor al cambiar la fecha
  };

  const handleHoraSeleccionada = (hora) => {
    setHoraSeleccionada(hora);
  };

  const handleAgendarReunion = async () => {
    try {
      if (!fechaSeleccionada || !horaSeleccionada) {
        console.error("Debe seleccionar una fecha y hora antes de agendar.");
        return;
      }
  
      const fechaHoraSeleccionada = new Date(fechaSeleccionada);
      fechaHoraSeleccionada.setHours(parseInt(horaSeleccionada), 0, 0, 0);
  
      const response = await axios.post(`${config.URL_HOST}/agendaryiyer/`, {
        idyiyer: id,
        idyiyu: userData.id,
        fecha: fechaHoraSeleccionada.toISOString(),
        hora: horaSeleccionada,
        estado: "pendiente",
      });
  
      console.log(response.data.message);
  
      setIsSuccess(true); // Establecer isSuccess en true si la solicitud es exitosa
    } catch (error) {
      console.error("Error al agendar la reunión:", error);
    }
  };

  return (
    <section className="flex flex-col sm:flex-row justify-center align-middle w-full bg-gray-50 p-4 font-outfit py-10">
      <div className="flex flex-col justify-center items-center w-full bg-white p-8 rounded-lg shadow-md gap-3 max-w-[750px]">
        <h1 className="font-bold text-2xl self-start">Agendar con un yiyer</h1>

        {/* DATOS DEL YIYER */}
        <DatosYiyerAgendamiento datosYiyer={yiyerData} />
        

        {isLoading ? ( // Mostrar Spinner si isLoading es true
          <Spinner color="orange" size="large" />
        ) : (
          <>
            <div className="flex flex-col md:flex-row gap-3">
              {/* CALENDARIO DATEPICKER */}
              <div className="flex flex-col items-center text-center gap-4 bg-blue-gray-50 border border-blue-gray-100 p-4 rounded-md w-full">
                <AgendamientoCalendario
                  fechasYiyer={disponibilidadYiyer}
                  onFechaSeleccionada={handleFechaSeleccionada}
                />
              </div>

              {/* ACÁ mostrar las horas diponibles */}
              <div className="flex flex-col items-center text-center gap-4 bg-blue-gray-50 border border-blue-gray-100 p-4 rounded-md w-full">
                <p className="text-sm text-blue-gray-600 mb-3">
                  <span className="text-blue-gray-50 rounded-full px-2.5 py-1 bg-deep-orange-700 mr-2">
                    2
                  </span>{" "}
                  Selecciona la hora de la sesión
                </p>
                <div className="flex flex-wrap gap-3 items-center justify-center">
                  {horasDisponibles.map((hora, index) => {
                    const horaOcupada = horariosOcupados.some(horario => {
                      const horarioDate = new Date(horario);
                      const horaHorario = horarioDate.getHours();
                      const horaSeleccionadaInt = parseInt(hora.split(":")[0]);
                      return (
                        horarioDate.toISOString().split("T")[0] ===
                          fechaSeleccionada?.toISOString().split("T")[0] &&
                        horaHorario === horaSeleccionadaInt
                      );
                    });

                    return (
                      <button
                        key={index}
                        onClick={() => handleHoraSeleccionada(hora)}
                        className={`bg-gray-500 text-white px-4 py-2 rounded-md ${
                          horaOcupada ? "cursor-not-allowed opacity-50" : ""
                        }`}
                        disabled={horaOcupada} // Deshabilita el botón si la hora está ocupada
                      >
                        {hora}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>

            {horaSeleccionada ? (
              <div className="flex flex-col items-center text-center gap-4 bg-blue-gray-50 border border-blue-gray-100 p-4 rounded-md w-full">
                <Button onClick={handleAgendarReunion}>Agendar</Button>
              </div>
            ) : null}

            {isSuccess && ( // Mostrar Collapse si isSuccess es true
              <Collapse open={isSuccess}>
                <Alert
                  variant="outlined"
                  color="green"
                  className="mt-2 mx-auto text-center justify-center"
                >
                  La reunión se ha agendado con éxito
                </Alert>
              </Collapse>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default AgendarYiyer_v2;
