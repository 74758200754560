import React from "react";
import imagesApp from "../assets/images/staticimgs";
import YiyersDestacadosHome from "../components/HomeComponents/YiyersDestacadosHome";
import CarruselHome from "../components/HomeComponents/CarruselHome";
import ValoresHome from "../components/HomeComponents/ValoresHome";
import ComoFuncionaHome from "../components/HomeComponents/ComoFuncionaHome";

const Home = () => {
  return (
    <>
      <CarruselHome />

      <ComoFuncionaHome />

      <YiyersDestacadosHome />

      <ValoresHome />
        

      <section className="bg-Flamingo-0 flex flex-col justify-center items-center w-screen sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center sm:text-start">
        <img
          src={imagesApp.home03}
          alt="imagen de videollamada"
          className="object-contain rounded-2xl basis-1/2 w-full max-w-xl sm:w-1/2 lg:max-w-md"
        />

        <article className="flex flex-col basis-1/2 lg:max-w-xl text-Flamingo-3 space-y-5">
          <h1 className=" text-3xl font-extrabold max-w-prose">
            Forma parte de esta{" "}
            <span className="text-Flamingo-2">nueva generación</span> de
            Yiyers
          </h1>
        </article>
      </section>
    </>
  );
};

export default Home;
