import React from "react";

const CardValores = ({strokeColor, bgColor, titulo, texto}) => {
  return (
    <div className="w-10/12 sm:w-60 text-sm space-y-3 p-5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={`w-14 h-14 mx-auto ${strokeColor} stroke-2 ${bgColor} p-2 rounded-lg drop-shadow-sm`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
      </svg>
      <h1 className=" text-xl font-bold">{titulo}</h1>
      <p className="text-left">
        {texto}
      </p>
    </div>
  );
};

export default CardValores;
