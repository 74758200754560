import React, { useEffect, useState } from 'react';
import config from '../../util/config';
import axios from 'axios';
import { useAuth } from "../../context/AuthContext";
import { Button, Badge } from '@material-tailwind/react';
import { HiOutlineBell, HiCalendarDays } from "react-icons/hi2";

function NotificacionesHeader({ userId, userRol }) {
  const { isAuthenticated } = useAuth(); 
  const [notifications, setNotifications] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${config.URL_HOST}/notificaciones/agenda/${userId}/${userRol}`);
        const { data } = response;
        setNotifications(data);
      } catch (error) {
        console.error("Error al obtener las notificaciones:", error);
      }
    };

    fetchNotifications();
  }, [userId, userRol]);

  const formatearFecha = (fecha) => {
    const date = new Date(fecha);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('es-ES', options);
  };

  const formatearHora = (hora) => {
    const date = new Date(hora);
    return date.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
  };

  const marcarComoLeida = async (notificationId) => {
    try {
      await axios.put(`${config.URL_HOST}/notificaciones/${notificationId}`);
      const updatedNotifications = notifications.map(notification => {
        if (notification.id === notificationId) {
          return { ...notification, estado: 'leida' };
        }
        return notification;
      });
      setNotifications(updatedNotifications);
    } catch (error) {
      console.error("Error al marcar la notificación como leída:", error);
    }
  };

  return (
    <div className="relative">
      <Badge className='px-1.5' content={notifications.filter(notification => notification.estado === 'pendiente').length}>
  <Button
    onClick={() => {
      setDropdownOpen(!dropdownOpen);
      if (dropdownOpen) {
        notifications.filter(notification => notification.estado === 'pendiente').slice(0, 5).forEach(notification => {
          marcarComoLeida(notification.id);
        });
      }
    }}
    className='text-xl bg-blue-gray-700 px-3'
  >
    <HiOutlineBell />
  </Button>
</Badge>

      {dropdownOpen && (
        <div className="absolute top-12 right-0 bg-blue-gray-300 p-4 rounded-lg shadow-md w-[250px] md:w-[350px]">
          {notifications.length > 0 ? (
            <ul className="flex flex-col gap-3">
              {/* Mostrar notificaciones */}
              {notifications.slice(0, 5).map((notification, index) => (
                <li key={notification.id} className={`font-outfit text-sm p-3 border border-blue-gray-200 bg-blue-gray-${notification.estado === 'pendiente' ? '50' : '200'} rounded-lg shadow-md`}>
                  <span className='flex flex-row gap-3'><HiCalendarDays className='text-5xl my-auto'/>{notification.nombre_yiyu} {notification.apellido_yiyu} ha agendado una sesión el día {formatearFecha(notification.fecha)} a las {formatearHora(notification.fecha)} horas.</span>
                  {/* {notification.estado === 'pendiente' && (
                    <Button onClick={() => marcarComoLeida(notification.id)}>Marcar como leída</Button>
                  )} */}
                </li>
              ))}
            </ul>
          ) : <p className='font-outfit text-center text-white'>No hay notificaciones</p>}
        </div>
      )}
    </div>
  );
}

export default NotificacionesHeader;
