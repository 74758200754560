import axios from "axios";
import React, { useState } from "react";
import config from "../../util/config";
import { useNavigate } from "react-router-dom";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import FormRegistroYiyer from "./FormRegistroYiyer";
import FormRegistroYiyu from "./FormRegistroYiyu";


function SignUpForm() {
  const [activeTab, setActiveTab] = useState("1");

  const handleTabSwitch = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="flex flex-row justify-center items-center p-0 lg:p-8 w-full bg-Gray-1 font-outfit gap-4">
      <Tabs value={activeTab} onChange={handleTabSwitch}>
        <p className="w-11/12 mx-auto mt-10 text-center text-sm text-blue-gray-600">Selecciona el perfil con el cual te quieres registrar</p>
        <TabsHeader className="mt-6 w-11/12 mx-auto bg-orange-500">
          <Tab value="1" className=" font-outfit font-bold text-blue-gray-700">Yiyer</Tab>
          <Tab value="2" className=" font-outfit font-bold text-blue-gray-700">Yiyu</Tab>
        </TabsHeader>
        <TabsBody>
          <TabPanel value="1">
            <FormRegistroYiyer />
          </TabPanel>
          <TabPanel value="2">
            <FormRegistroYiyu />
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
}

export default SignUpForm;
