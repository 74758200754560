import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { Button, Input, Textarea, Avatar, Spinner } from '@material-tailwind/react';
import config from "../../util/config";
import MensajeErrorForms from './MensajeErrorForms';
import { ContextUser } from '../../context/ContextUser';

function EditarMiPerfilYiyer() {
  const datoUsuario = JSON.parse(localStorage.getItem("userData"));
  const { setYiyerData } = useContext(ContextUser);
  const [errorText, setErrorText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const fileInputRef = useRef();

  const [form, setForm] = useState({
    nombre: datoUsuario.nombre || '',
    apellido: datoUsuario.apellido || '',
    pais: datoUsuario.pais || '',
    profesion: datoUsuario.profesion || '',
    linkedin: datoUsuario.linkedin || '',
    intro: datoUsuario.intro || '',
    temas: datoUsuario.temas || '',
    experiencia: datoUsuario.experiencia || '',
    foto: datoUsuario.temas || null,
    email: datoUsuario.email,
  });

  useEffect(() => {
    setIsEditing(true); // Esto asegura que el formulario esté siempre en modo de edición al cargar el componente
  }, []);

  const handleChange = (e) => {
    setForm((prevForm) => ({ ...prevForm, [e.target.name]: e.target.value }));
    setIsOpen(false);
  };

  const handleFileChange = (e) => {
    const newFoto = e.target.files[0];
    if (newFoto) {
      setForm({ ...form, foto: newFoto });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const formData = new FormData();
    formData.append('nombre', form.nombre);
    formData.append('apellido', form.apellido);
    formData.append('pais', form.pais);
    formData.append('profesion', form.profesion);
    formData.append('linkedin', form.linkedin);
    formData.append('intro', form.intro);
    formData.append('temas', form.temas);
    formData.append('experiencia', form.experiencia);
    // Añade la foto al formData solo si se ha seleccionado una nueva
    if (form.foto && typeof form.foto === 'object') {
      formData.append('foto', form.foto);
    }
    formData.append('email', form.email);
  
    try {
      const response = await axios.put(`${config.URL_HOST}/editprofile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      setIsOpen(true)
      setIsEditing(false)
      setErrorText("El usuario se ha actualizado con éxito");
      localStorage.setItem("userData", JSON.stringify(response.data.user));
      setYiyerData(response.data.user)
    } catch (error) {
      setIsOpen(true);
      
      if (error.response.data.code === '23505') {
        setErrorText("El email ya está registrado");
      } else {
        setErrorText("Ha ocurrido un error, inténtalo más tarde");
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <section className='flex flex-row justify-center align-middle p-10 w-full bg-Gray-1 font-outfit gap-4'>
      
      <form
        onSubmit={handleSubmit}
        method="POST"
        encType="multipart/form-data"
        className="flex flex-col gap-3 bg-white p-8 rounded-xl shadow-md w-full sm:w-8/12"
      >
        { isOpen ? <MensajeErrorForms open={isOpen} texto={errorText}/> : null   }
        <h2 className="text-2xl font-bold mb-5 text-orange-500">Edita tus datos</h2>
        <div className='flex flex-col items-center gap-4 mb-6'>
          <Avatar src={replaceImageUrl(datoUsuario.imgurl)} alt="Imagen de perfil" size="xxl" className='border-4 border-blue-gray-900'/>
          {isEditing ? (
            <>
              <label htmlFor='fotoInput' className="cursor-pointer text-blue-500">
                <Button onClick={handleButtonClick}>Cambiar foto</Button>
                <input
                  type="file"
                  accept="image/*"
                  id="fotoInput"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  className="hidden"
                />
              </label>
              {form.foto && typeof form.foto === 'object' && (
                <span className="ml-2">{form.foto.name}</span> 
              )}
            </>
          ) : null}
        </div>

        <div className='flex flex-col md:flex-row gap-3'>
          <Input
            label='Nombre'
            name="nombre"
            onChange={handleChange}
            value={form.nombre}
            placeholder={datoUsuario.nombre}
            className="block w-full p-2 rounded border border-gray-300 mb-3"
            disabled={!isEditing}
          />

          <Input
            label='Apellido'
            name="apellido"
            onChange={handleChange}
            value={form.apellido}
            placeholder={datoUsuario.apellido}
            className="block w-full p-2 rounded border border-gray-300 mb-3"
            disabled={!isEditing}
          />
        </div>

        <div className='flex flex-col md:flex-row gap-3'>
          <Input
            label='Email'
            name="email"
            onChange={handleChange}
            value={form.email}
            placeholder={datoUsuario.email}
            className="block w-full p-2 rounded border border-gray-300 mb-3"
            disabled={true}
          />
          <Input
            label='País'
            name="pais"
            onChange={handleChange}
            value={form.pais}
            placeholder={datoUsuario.pais}
            className="block w-full p-2 rounded border border-gray-300 mb-3"
            disabled={!isEditing}
          />
        </div>

          

        <Input
            label='Profesión'
            name="profesion"
            onChange={handleChange}
            value={form.profesion}
            placeholder={datoUsuario.profesion}
            className="block w-full p-2 rounded border border-gray-300 mb-3"
            disabled={!isEditing}
          />

<Input
            label='Linkedin'
            name="linkedin"
            onChange={handleChange}
            value={form.linkedin}
            placeholder={datoUsuario.linkedin}
            className="block w-full p-2 rounded border border-gray-300 mb-3"
            disabled={!isEditing}
          />
        <Textarea
          label='Introducción'
          variant="outlined"
          name="intro"
          onChange={handleChange}
          value={form.intro}
          className="block w-full p-2 rounded border border-gray-300 mb-3"
          disabled={!isEditing}
        />
        <Textarea
          label='Temas que quiero resolver'
          variant="outlined"
          name="temas"
          onChange={handleChange}
          value={form.temas}
          className="block w-full p-2 rounded border border-gray-300 mb-3"
          disabled={!isEditing}
        />

        <Textarea
          label='Mi experiencia'
          variant="outlined"
          name="experiencia"
          onChange={handleChange}
          value={form.experiencia}
          className="block w-full p-2 rounded border border-gray-300 mb-3"
          disabled={!isEditing}
        /> 

        {isLoading ? <Spinner className="h-5 w-5 mx-auto" /> : <input
          type="submit"
          value="Enviar"
          className={`${!isEditing ? 'hidden' : ''} block w-full bg-orange-500 hover:bg-orange-400 p-2 rounded text-white font-bold cursor-pointer`}
          disabled={!isEditing}
        />}
        {!isEditing && (
          <button onClick={() => setIsEditing(true)} className="block w-full bg-blue-500 hover:bg-blue-400 p-2 rounded text-white font-bold cursor-pointer">
            Editar
          </button>
        )}
      </form>
    </section>
  );
}

export default EditarMiPerfilYiyer;
