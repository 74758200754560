import React, { useEffect } from "react";
import { useContext } from "react";
import CardYiyers from "../comun/CardYiyers";
import { ContextUser } from "../../context/ContextUser";
import axios from "axios";
import config from "../../util/config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const YiyersDestacadosHome = () => {
  const { usersData, setUsersData } = useContext(ContextUser);
  const copiaArray = usersData.slice(0, 5);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  
  useEffect(() => {
    axios
      .get(`${config.URL_HOST}/yiyers`)
      .then((result) => {
        setUsersData(result.data);
      })
      .catch((error) => console.log(error));
  }, []);

  // Función para modificar la URL de la imagen
  const replaceImageUrl = (url) => {
    return url.replace("/home/yiyazcom/public_html", "https://www.yiyaz.com");
  };

  return (
    <section className="bg-gradient-to-t from-blue-gray-50 to-white flex flex-col str justify-center align-middle py-10 space-y-5 sm:space-x-8 text-center font-outfit">
      <h1 className="text-3xl font-extrabold text-center"> Yiyers destacados </h1>

      <Slider {...settings}>
        {Array.isArray(copiaArray) &&
          copiaArray.map((user) => (
            <div key={user.id}>
              <CardYiyers
                id={user.id}
                picture={replaceImageUrl(user.imgurl)}
                name={user.nombre}
                lastname={user.apellido}
                profesion={user.profesion}
              />
            </div>
          ))}
      </Slider>
    </section>
  );
};

export default YiyersDestacadosHome;
