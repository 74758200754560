import React from 'react'
import imagesApp from '../../assets/images/staticimgs';
import { Avatar } from '@material-tailwind/react';


function DatosYiyerAgendamiento({ datosYiyer }) {
  return (
    <div className="flex flex-row gap-3 bg-blue-gray-50 border border-blue-gray-100 p-2 rounded-md w-full">
          {datosYiyer.imgurl ? (
            <Avatar
              src={datosYiyer.imgurl}
              alt="avatar"
              size="md"
              loading="lazy"
              withBorder={true}
              onError={(e) => {
                e.target.src = imagesApp.noImg;
              }}
            />
          ) : (
            <img
              src={imagesApp.noImg}
              alt="avatar"
              className="w-20 h-20 rounded-full"
            />
          )}
          <div>
            <p className=" text-base font-bold">
              {datosYiyer.nombre} {datosYiyer.apellido}
            </p>
            <p>{datosYiyer.profesion}</p>
          </div>
        </div>
  )
}

export default DatosYiyerAgendamiento
