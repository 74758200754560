import React from "react";
import NewsBar from "./NewsBar";
import YifyLogo from "../../assets/images/Logo.svg";
import ButtonNavHeader from "./ButtonNavHeader";
import { Link } from "react-router-dom";
import ButtonsLoginRegisterHeader from "./ButtonsLoginRegisterHeader";
import LoguedButtonHeader from "./LoguedButtonHeader";
import { useAuth } from "../../context/AuthContext";
import NotificacionesHeader from "./NotificacionesHeader";

function Header() {
 const { isAuthenticated } = useAuth(); 
 const scrollToSection = () => {
  const element = document.querySelector("#comofunciona");
  if (element) {
    document.body.classList.add("scroll-smooth"); // Agrega la clase para suavizar el scroll
    element.scrollIntoView({ behavior: "smooth" });
    document.body.classList.remove("scroll-smooth"); // Elimina la clase después del desplazamiento
  }
}

const userData = JSON.parse(localStorage.getItem("userData")); // Obtener userData del localStorage

  return (
    <div className="right-0 left-0 z-50 w-screen fixed top-0">
      <NewsBar />
      <header className="flex flex-row justify-around sm:justify-around items-center p-3 bg-White">
        <Link to={"/"}>
          <img src={YifyLogo} alt="Logo Yiyaz" className="w-20" />
        </Link>

        <div className="flex items-center space-x-4 font-outfit text-xs">
          <ButtonNavHeader rutaHeader={"/"} buttonText={"Inicio"} />
          <ButtonNavHeader rutaHeader={"/comunidad"} buttonText={"Buscar un yiyer"} />
          <a href="/#comofunciona" onClick={scrollToSection} className="hidden sm:inline whitespace-nowrap transition ease-in-out delay-150 p-2 text-xs lg:text-sm rounded-md hover:bg-Flamingo-1 hover:text-White focus:bg-Flamingo-1 focus:text-White">Cómo Funciona</a>
        </div>

          
          <ButtonsLoginRegisterHeader />
          <div className="flex flex-row gap-3">
          {isAuthenticated ? <NotificacionesHeader userId={userData.id} userRol={userData.rol}/> : null}
          <LoguedButtonHeader />
          </div>
          
          {/* <NotificacionesHeader userId={userData.id} /> */}

      </header>
    </div>
  );
}

export default Header;
