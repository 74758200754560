import React from 'react'
import CardComoFuncionaH from './CardComoFuncionaH'
import imagesApp from '../../assets/images/staticimgs'


function ComoFuncionaHome() {
  return (
    <section id='comofunciona' className='bg-gradient-to-t from-deep-orange-50 to-white font-outfit my-5 py-7'>
        <h1 className="text-3xl font-extrabold text-center"> Cómo Funciona </h1>
        <div className='flex flex-row justify-start lg:justify-center overflow-x-auto mt-5 py-6 px-6 gap-10 scroll-smooth'>
        <CardComoFuncionaH 
        titulo="Escoge tu área de interés"
        orden="1"
        image={imagesApp.how01}
        />
        
        <CardComoFuncionaH 
        titulo="Escoge al Yiyer que más se ajuste a tus necesidades"
        orden="2"
        image={imagesApp.how02}
        />

        <CardComoFuncionaH 
        titulo="Agenda una sesión con tu Yiyer"
        orden="3" 
        image={imagesApp.how03}
        />
        <CardComoFuncionaH 
        titulo="Ten tu conversación de valor"
        orden="4" 
        image={imagesApp.how04}
        />
        </div>
    </section>
  )
}

export default ComoFuncionaHome
