import React from "react";
import imagesApp from "../assets/images/staticimgs";
import CardValores from "../components/HomeComponents/CardValores";


const AcercaDe = () => {
  return (
    <>
      <section className="bg-Flamingo-3 flex justify-center items-center w-full sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center relative z-0">
        <article className="flex lg:max-w-xl py-8">
          <h1 className="text-3xl sm:text-4xl text-Flamingo-0 font-extrabold max-w-prose text-center">
            “Experiencias de valor a través de conversaciones de valor”
          </h1>
        </article>
      </section>
      <section className="bg-Gray-2 flex flex-col justify-center items-center w-screen sm:flex-row p-10 sm:px-20 space-y-8 sm:space-x-8 text-center sm:text-start relative z-0">
        <article className="flex flex-col basis-1/2 lg:max-w-xl space-y-5">
          <h1 className=" text-Flamingo-0 text-3xl font-bold max-w-prose">
            Nuestra Historia
          </h1>
          <p className=" text-sm text-gray-700">
         <span className=" text-lg text-orange-400">Descubre el Poder Transformador de una Buena Conversación</span> <br /> 

¿Cuántas decisiones importantes en tu vida se han dado luego de un consejo? Recuerdo cómo mi madre, tras largos días de trabajo, aprovechaba el corto tiempo antes de dormir para conversar. Aunque breve, cada conversación era profunda y cargada de emociones.<br /> <br /> 

Mi madre, conocida como Yiya, siempre creyó que, aunque el tiempo fuera escaso, una conversación poderosa podía cambiar vidas. Esta premisa la llevó a ser recordada por muchos como un ser especial, cuyo legado vive en la historia de quienes la conocieron.<br /> <br /> 

Inspirados por esta creencia, nace Yiyaz, un Hub de Talentos e Inteligencia donde personas que buscan recomendaciones encuentran a expertos para recibir asesoramiento personalizado. Todos tenemos un conocimiento valioso obtenido de múltiples experiencias. <br /> <br /> 

En Yiyaz, creemos en el poder transformador de una buena conversación. Nuestros expertos están aquí para escuchar y ofrecer consejos que pueden ayudarte a tomar decisiones importantes y ver el mundo con nuevos ojos. Te invitamos a experimentar cómo una conversación significativa puede cambiar tu vida.

Únete a Yiyaz y descubre el impacto de una conversación poderosa. Porque, a veces, un simple consejo es todo lo que necesitas para transformar tu vida. ¡Hablemos de lo que tú quieras!
          </p>
        </article>

        <img
          src={imagesApp.about01}
          alt="imagen de videollamada"
          className="object-contain rounded-2xl basis-1/2 w-full max-w-xl sm:w-1/2 lg:max-w-md"
        />
      </section>

      <section className="bg-Bunting-3 flex flex-col justify-center items-center w-screen py-10  space-y-5 sm:space-x-8 text-center">
        <h1 className=" text-3xl font-extrabold max-w-prose str">
          Nuestros valores
        </h1>
        <p>
          El interés genuino por crear espacios de colaboración y aprendizaje a
          través de conversaciones de valor.
        </p>
        <article className="flex flex-wrap space-y-2 sm:space-y-0 justify-center items-center w-11/12">
          <CardValores strokeColor="stroke-Flamingo-0" bgColor="bg-Flamingo-3" titulo="Empatía" texto="Creemos que la clave de toda conversación de valor es la empatía, en donde ambas personas intentan comprender y ponersele en la situación del otro." />
          <CardValores strokeColor="stroke-Shark-0" bgColor="bg-White" titulo="Confianza" texto="Toda conversación efectiva se basa en crear un ambiente de confianza mutua, en donde ambas personas puedan expresar sus opiniones sin prejuicios." />
          <CardValores strokeColor="stroke-Leopard-0" bgColor="bg-Leopard-3" titulo="Autenticidad" texto="No nos interesan las pretenciones, queremos que todos los miembros de Yiyaz, tanto usuarios como Yiyers, sean genuinos, auténticos y fieles a ellos mismos." />
          {/* <CardValores strokeColor="stroke-Bunting-0" bgColor="bg-White" titulo="Characteristic" texto="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." /> */}
        </article>
        
      </section>
    </>
  );
};

export default AcercaDe;
