import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isSameDay } from "date-fns";
import es from "date-fns/locale/es";

function AgendamientoCalendario({ fechasYiyer, onFechaSeleccionada }) {
  const [selectedDate, setSelectedDate] = useState(null);

  const fechasDisponibles = fechasYiyer.map((fecha) =>
    new Date(fecha.date)
  );


  const customDateClass = (date) => {
    return fechasDisponibles.some((fecha) => isSameDay(fecha, date))
      ? "bg-orange-500 text-white rounded-full"
      : null;
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    onFechaSeleccionada(date); // Llama a la función de devolución de llamada del padre con la fecha seleccionada
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm text-blue-gray-600 mb-3"><span className="text-blue-gray-50 rounded-full px-2.5 py-1 bg-deep-orange-700 mr-2">1</span>  Selecciona una fecha marcada como disponible en el calendario</p>
      <DatePicker
        inline
        locale={es}
        selected={selectedDate}
        onChange={handleDateChange}
        highlightDates={fechasDisponibles}
        dateFormat="dd/MM/yyyy"
        dayClassName={customDateClass}
        className="text-center"
      />
      {selectedDate && (
        <div>
          <p>Fecha seleccionada: {selectedDate.toLocaleDateString()}</p>
        </div>
      )}
    </div>
  );
}

export default AgendamientoCalendario;