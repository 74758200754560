import React, { useEffect, useState } from "react";
import axios from "axios";
import { parseISO, format } from "date-fns";
import config from "../../util/config";
import { Spinner } from "@material-tailwind/react";
import { es } from "date-fns/locale";
import { FaTrash } from "react-icons/fa"; // ícono de eliminación


const MiDisponibilidad = ({ userId, handleOpen }) => {
  const [horarios, setHorarios] = useState([]);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {
    const fetchHorarios = async () => {
      if (userId) {
        try {
          const response = await axios.get(
            `${config.URL_HOST}/midisponibilidad/${userId}`
          );
          setHorarios(response.data);
          setLoading(false);
        } catch (error) {
          console.error("Error al cargar los datos:", error);
        }
      }
    };

    fetchHorarios();
  }, [userId, handleOpen]);

  

  const handleDelete = async (id) => {
    // Lógica para eliminar el horario con el ID proporcionado
    try {
      // Llama a la API para eliminar el registro con el ID
      await axios.delete(`${config.URL_HOST}/eliminarhorario/${id}`);
      
      // Actualiza la lista de horarios después de eliminar
      const updatedHorarios = horarios.filter((horario) => horario.id !== id);
      setHorarios(updatedHorarios);
    } catch (error) {
      console.error("Error al eliminar el horario:", error);
    }
  };

  const currentDate = new Date();
  const currentDateString = format(currentDate, "yyyy-MM-dd");

  const futureHorarios = horarios.filter((horario) => {
    const horarioDate = format(parseISO(horario.date), "yyyy-MM-dd");
    return horarioDate >= currentDateString;
  });

  const horarioElements = futureHorarios.map((horario) => (
    <tr key={horario.id} className="text-center text-xs md:text-sm text-gray-600">
      <td className="border p-1">
        {format(parseISO(horario.date), "dd 'de' MMMM 'de' yyyy", {
          locale: es,
        })}
      </td>
      <td className="border p-1">{horario.start_time}</td>
      <td className="border p-1">{horario.end_time}</td>
      <td className="border p-1">
        <button onClick={() => handleDelete(horario.id)}>
          <FaTrash />
        </button>
      </td>
    </tr>
  ));

  return (
    <div className="w-full relative">
      {loading ? ( 
        <>
          <Spinner className="mx-auto h-10 w-10" />
        </>
      ) : (
        <div className="flex flex-col">
          <table className="w-full border-collapse border mt-2 mx-auto bg-white">
            <thead>
              <tr className="bg-gray-400 text-xs text-gray-800 uppercase">
                <th className="border p-2">Fecha</th>
                <th className="border p-2">Desde</th>
                <th className="border p-2">Hasta</th>
                <th className="border p-2">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {horarioElements.length > 0 ? (
                horarioElements
              ) : (
                <tr>
                  <td colSpan="4" className="text-center p-1 text-xs">
                    No hay horarios guardados.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default MiDisponibilidad;
